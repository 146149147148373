import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Icon } from 'semantic-ui-react'
import { AddedChargeItem } from './AddedChargeItem'
import { CartItem } from './CartItem'

import Styles from './styles.module.css'
import { EllipsisItem } from './EllipsisItem'
import { CartPopupListFooter } from './CartPopupListFooter'

class CartPopupList extends Component {
    static propTypes = {
        items: PropTypes.array,
    }

    bumpUp({ productId, quantity }) {
        const newQuantity = quantity + 1

        this.props.handleCartChange({
            productId,
            quantity: newQuantity,
            lastAction: 'add'
        })
    }

    bumpDown({ productId, quantity }) {
        const newQuantity = quantity - 1

        this.props.handleCartChange({
            productId,
            quantity: newQuantity,
            lastAction: 'remove'
        })
    }

    render() {
        const { items, addedCharges, notShown, handleClose, currentCartValue, currentLocale, currentUser, livdomZipcodes } =
            this.props

        // Assessing livdom details
        let livdomZip
        if (currentUser) {
            if (currentUser.address_zip) {
                livdomZip = currentUser.address_zip
            }
            else if (currentUser.livdom) {
                livdomZip = currentUser.livdom.address_zip
            }
        }

        let userZone = livdomZipcodes &&
            (livdomZipcodes.content[0].zone1.includes(livdomZip) ?
                1
                :
                livdomZipcodes.content[0].zone2.includes(livdomZip) ?
                    2
                    :
                    livdomZipcodes.content[0].zone3.includes(livdomZip) && 3)

        return (
            <div
                className={Styles.ListContainer}
                id={this.props.id}
                title="cart-list"
            >
                {currentLocale?.code === 'DOM' && ((userZone === 1 && currentCartValue >= 3000) || (userZone === 2 && currentCartValue >= 6000) || (userZone === 3 && currentCartValue >= 9000)) &&
                    <div className={Styles.livdomMention}>
                        {userZone === 1 ? <p>Livraison offerte à partir de 150€ </p> : userZone === 2 ? <p>Livraison offerte à partir de 180€</p> : userZone === 3 && <p>Livraison offerte à partir de 210€</p>}
                    </div>
                }
                <Table
                    compact="very"
                    basic="very"
                    singleLine
                    fixed
                    unstackable
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={5}>Qté</Table.HeaderCell>
                            <Table.HeaderCell width={12}>
                                Articles
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                width={5}
                                textAlign="right"
                            >
                                Total
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {/* if > N */}
                        {notShown >= 1 ? (
                            <EllipsisItem notShown={notShown} />
                        ) : null}

                        {items.length > 0 ? (
                            items.map((item, index) => (
                                <CartItem
                                    key={index}
                                    item={item}
                                    bumpUp={this.bumpUp.bind(this, {
                                        productId: item.producerproduct_id,
                                        quantity: item.quantity,
                                    })}
                                    bumpDown={this.bumpDown.bind(this, {
                                        productId: item.producerproduct_id,
                                        quantity: item.quantity,
                                    })}
                                />
                            ))
                        ) : (
                            <Table.Row key="no-articles">
                                <Table.Cell colSpan="3">
                                    Aucun article dans le panier.
                                </Table.Cell>
                            </Table.Row>
                        )}

                        {items.length > 0 && addedCharges.length > 0
                            ? addedCharges.map(item => (
                                <AddedChargeItem item={item} />
                            ))
                            : null}
                    </Table.Body>

                    {currentCartValue ? (
                        <CartPopupListFooter
                            handleClose={handleClose}
                            currentCartValue={currentCartValue}
                        />
                    ) : (
                        ''
                    )}
                </Table>
                {currentLocale.code === 'DOM' &&
                    <div className={Styles.livdomCost}>
                        {((userZone === 1 && currentCartValue < 3000) || (userZone === 2 && currentCartValue < 6000) || (userZone === 3 && currentCartValue < 9000)) &&
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon className={Styles.warningSign} name='warning sign' />
                                {userZone === 1 ?
                                    currentCartValue < 3000 && <p>Livraison possible à partir de 30€</p>
                                    : userZone === 2 ?
                                        currentCartValue < 6000 && <p>Livraison possible à partir de 60€</p>
                                        : userZone === 3 &&
                                        currentCartValue < 9000 && <p>Livraison possible à partir de 90€</p>
                                }
                            </div>
                        }
                        {((userZone === 1 && currentCartValue >= 3000) || (userZone === 2 && currentCartValue >= 6000) || (userZone === 3 && currentCartValue >= 9000)) &&
                            <>
                                <p>Frais de livraison </p>
                                <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                    {userZone && userZone === 1 && (
                                        currentCartValue < 6000 ? '+8,90€' :
                                            currentCartValue < 9000 ? '+6,90€' :
                                                currentCartValue < 12000 ? '+3,90€' :
                                                    currentCartValue < 15000 ? '+1,90€' :
                                                        currentCartValue >= 15000 && '+0,00€'
                                    )
                                    }
                                    {userZone && userZone === 2 && (
                                        currentCartValue < 9000 ? '+8,90€' :
                                            currentCartValue < 12000 ? '+6,90€' :
                                                currentCartValue < 15000 ? '+3,90€' :
                                                    currentCartValue < 18000 ? '+1,90€' :
                                                        currentCartValue >= 18000 && '+0,00€'
                                    )
                                    }
                                    {userZone && userZone === 3 && (
                                        currentCartValue < 12000 ? '+8,90€' :
                                            currentCartValue < 15000 ? '+6,90€' :
                                                currentCartValue < 18000 ? '+3,90€' :
                                                    currentCartValue < 21000 ? '+1,90€' :
                                                        currentCartValue >= 21000 && '+0,00€'
                                    )
                                    }
                                </p>
                            </>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default CartPopupList
