import { applyMiddleware, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'

import rootReducer from './reducers'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import { composeWithDevTools } from "@redux-devtools/extension";

const initialState = {
    activeGlobalOrdersList: [],

    cart: {
        list: [],
        items: {},
    },

    categories: {},

    currentGlobalOrder: {
        isFetching: false,
        lastUpdate: false,
        didInvalidate: false,
    },

    currentLocale: {},

    currentUser: {},

    families: {},

    locales: {},
    localeList: [],

    nextGlobalOrdersList: [],

    productsHeaderContent: {},

    order: {},

    livdomZipcodes: [],

    products: {},
    producers: {},
    producersList: [],

    session: {},

    switchTo: {
        locale: null,
        globalOrder: null,
        removedProducts: null,
        substituteProducts: null,
    },
}

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'activeGlobalOrdersList',
        'cart',
        'currentLocale',
        'products',
        'order',
        'currentGlobalOrder',
        'switchTo',
        'orderRecovery',
        'productsHeaderContent',
        'livdomZipcodes'
    ],
}

export const initStore = () => {
    const enhancers = []
    const middleware = [thunk]

    const persistedReducer = persistReducer(persistConfig, rootReducer)

    const store = createStore(
        persistedReducer,
        initialState,
        composeWithDevTools(
            applyMiddleware(...middleware),
            ...enhancers
        )
    )

    const persistor = persistStore(store);

    return {
        store,
        persistor,
    }
}
