import React from 'react'
import { Form, Button } from 'semantic-ui-react'
import Styles from './styles.module.css'
import { Combobox, ComboboxInput, ComboboxOption, ComboboxPopover, ComboboxList } from '@reach/combobox'

function AddressForm({
    handleSubmitForm,
    currentUser,
    lastname,
    setLastname,
    firstname,
    setFirstname,
    telephone,
    setTelephone,
    value,
    data,
    setValue,
    setAddress_street,
    address_zip,
    setAddress_zip,
    setAddress_city,
    address_code,
    setAddress_code,
    address_instructions,
    setAddress_instructions,
    handleSelectAddress,
    zipcodes,
    formType,
    setShowForm
}) {
    return (
        <Form className={Styles.livdomForm} onSubmit={() => {
            handleSubmitForm()
            if (formType === 2) {
                setShowForm(false)
            }
        }}>
            <div className={Styles.Align}>
                <Form.Field
                    required
                    style={window.innerWidth < 1025 ? { width: '100%' } : currentUser.loggedin ? { margin: '5px 0', width: '33%' } : { margin: '5px 0', width: '50%' }}
                >
                    <label style={{ textAlign: 'start !important' }} htmlFor="lastname">Nom</label>
                    <input
                        name="lastname"
                        id="lastname"
                        placeholder=""
                        value={lastname || ''}
                        onChange={e => setLastname(e.target.value)}
                    />
                </Form.Field>

                <Form.Field
                    required
                    style={window.innerWidth < 1025 ? { width: '100%' } : currentUser.loggedin ? { margin: '5px 0', width: '33%' } : { margin: '5px 0', width: '50%' }}
                >
                    <label style={{ textAlign: 'start !important' }} htmlFor="firstname">Pr&eacute;nom</label>
                    <input
                        name="firstname"
                        id="firstname"
                        placeholder=""
                        value={firstname || ''}
                        onChange={e => setFirstname(e.target.value)}
                    />
                </Form.Field>
                {currentUser.loggedin &&
                    <Form.Field
                        required
                        style={window.innerWidth < 1025 ? { width: '100%' } : { margin: '5px 0', width: '33%' }}
                    >
                        <label style={{ textAlign: 'start !important' }} htmlFor="telephone">N° de t&eacute;l&eacute;phone</label>
                        <input
                            name="telephone"
                            id="telephone"
                            placeholder=""
                            value={telephone || ''}
                            onChange={e => setTelephone(e.target.value)}
                        />
                    </Form.Field>
                }
            </div>

            <Form.Field
                required
                style={{ width: '100%' }}>
                <label style={window.innerWidth < 1024 ? { textAlign: 'start !important' } : { textAlign: 'start !important' }} htmlFor="address">Adresse</label>
                <Combobox onSelect={handleSelectAddress}>
                    <ComboboxInput
                        name="address"
                        id="address"
                        autocomplete="off"
                        spellcheck='false'
                        autocorrect='off'
                        placeholder=""
                        onChange={e => {
                            if (address_zip) {
                                setAddress_zip(null)
                                setAddress_street(null)
                                setAddress_city(null)
                                setValue('')
                            }
                            else {
                                setValue(e.target.value)
                            }
                        }}
                        value={value}
                    />
                    <ComboboxPopover style={{ zIndex: '1500' }}>
                        <ComboboxList>
                            {data.map(({ place_id, description }, index) => {
                                return (
                                    <ComboboxOption
                                        key={place_id}
                                        value={description}
                                    />
                                )
                            })}
                        </ComboboxList>
                    </ComboboxPopover>
                </Combobox>
            </Form.Field>

            {address_zip && (
                (zipcodes.zone1.includes(address_zip) || zipcodes.zone2.includes(address_zip) || zipcodes.zone3.includes(address_zip)) ?
                    <p style={{ color: 'green', transform: 'translateY(-7.5px)' }}>Votre adresse est dans notre zone de livraison</p>
                    :
                    <p style={{ color: 'red', transform: 'translateY(-7.5px)' }}>Votre adresse n'est pas dans notre zone de livraison</p>)
            }

            <div className={Styles.Align}>
                {currentUser.loggedin &&
                    <Form.Field
                        style={window.innerWidth < 1025 ? { width: '100%' } : { margin: '5px 0', width: '50%' }}
                    >
                        <label style={{ textAlign: 'start !important' }} htmlFor="address_code">Code immeuble</label>
                        <input
                            name="address_code"
                            id="address_code"
                            placeholder=""
                            onChange={e => setAddress_code(e.target.value)}
                            value={address_code}
                        />
                    </Form.Field>
                }
                <Form.Field
                    style={window.innerWidth < 1025 ? { width: '100%' } : currentUser.loggedin ? { margin: '5px 0', width: '50%' } : { margin: '5px 0', width: '100%' }}
                >
                    <label style={{ textAlign: 'start !important' }} htmlFor="address_details">Complément d'adresse</label>
                    <input
                        name="address_instructions"
                        id="address_instructions"
                        placeholder=""
                        onChange={e => setAddress_instructions(e.target.value)}
                        value={address_instructions}
                    />
                </Form.Field>
            </div>
            {formType === 1 && address_zip && (zipcodes.zone1.includes(address_zip) || zipcodes.zone2.includes(address_zip) || zipcodes.zone3.includes(address_zip)) && firstname && lastname && ((currentUser.loggedin && telephone && telephone.length >= 10) || !currentUser.loggedin) &&
                <Button type="submit" className={Styles.formNext}>
                    {currentUser.loggedin ? <p><span>Valider et choisir mon jour de livraison</span>{"   >"}</p> : <p><span>Choisir mon jour de livraison</span>{"   >"}</p>}
                </Button>
            }
            {formType === 2 && firstname && lastname && address_zip && ((currentUser.loggedin && telephone && telephone.length >= 10) || !currentUser.loggedin) &&
                < Button type="submit" className={Styles.greenButton}>Valider</Button>
            }
        </Form>
    )
}

export default AddressForm