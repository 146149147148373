import * as Types from '../types'
import { getContentfulClient } from '../../utils/getContentfulClient'

const contentfulClient = getContentfulClient()

/**
 * fetch the current products list header layout from contentful, and then format into useable data
 * @returns {function} - dispatchable function to fetch header data
 */

export const fetchContentfulZipcodes = () => {
    return dispatch => {
        dispatch({
            type: Types.FETCH_CONTENTFUL_ZIPCODES_REQUEST,
        })

        return contentfulClient
            .getEntries({
                content_type: 'deliveryPostalCodes',
                select: 'fields.zone1,fields.zone2,fields.zone3', // must not put a space between elements !!!
            })
            .then(function (result) {
                let items =
                    result &&
                    result.items &&
                    result.items.map(item => {
                        const output = {}
                        if (!item.fields) {
                            return output
                        }
                        if (item.fields.zone1) {
                            output.zone1 = item.fields.zone1
                        }
                        if (item.fields.zone2) {
                            output.zone2 = item.fields.zone2
                        }
                        if (item.fields.zone3) {
                            output.zone3 = item.fields.zone3
                        }

                        return output
                    })

                dispatch({
                    type: Types.FETCH_CONTENTFUL_ZIPCODES_SUCCESS,
                    items,
                })
            })
            .catch(error => {
                dispatch({
                    type: Types.FETCH_CONTENTFUL_ZIPCODES_FAIL,
                    error,
                })
            })
    }
}
