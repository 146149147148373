import { Cart } from '../../../../../models'
const convertToParseableRepresentation = (dateAsString) => dateAsString.replace(/-/g, '/')

export const mapStateToProps = ({
    currentUser,
    currentLocale,
    livdomZipcodes,
    currentGlobalOrder,
    cart: { list, items: quantities },
    order,
    products,
    available: { products: available },
}) => {
    const cart = new Cart({
        list,
        quantities,
        products,
        available,
        validateConsumer: true,
        isLocalePrivate: currentLocale && !!currentLocale.private,
        isLocaleHomeDelivery: currentLocale && currentLocale.code === 'DOM',
        currentLocale,
    })

    const amountTotal = cart.getTotalTTC()
    const userCredit = currentUser.current_credit

    // Assessing userZone details if livdom
    let livdomFee = 0
    if (currentLocale && currentLocale.code === 'DOM') {
        let livdomZip = currentUser?.address_zip || null
        let userZone = (livdomZipcodes &&
            (livdomZipcodes.content[0].zone1.includes(livdomZip) ?
                1
                :
                livdomZipcodes.content[0].zone2.includes(livdomZip) ?
                    2
                    :
                    livdomZipcodes.content[0].zone3.includes(livdomZip) && 3)) || null
        if (userZone === 1) {
            if (amountTotal < 6000) {
                livdomFee = 890
            }
            else if (amountTotal < 9000) {
                livdomFee = 690
            }
            else if (amountTotal < 12000) {
                livdomFee = 390
            }
            else if (amountTotal < 15000) {
                livdomFee = 190
            }
            else if (amountTotal >= 15000) {
                livdomFee = 0
            }
        }
        else if (userZone === 2) {
            if (amountTotal < 9000) {
                livdomFee = 890
            }
            else if (amountTotal < 12000) {
                livdomFee = 690
            }
            else if (amountTotal < 15000) {
                livdomFee = 390
            }
            else if (amountTotal < 18000) {
                livdomFee = 190
            }
            else if (amountTotal >= 18000) {
                livdomFee = 0
            }
        }
        else if (userZone === 3) {
            if (amountTotal < 12000) {
                livdomFee = 890
            }
            else if (amountTotal < 15000) {
                livdomFee = 690
            }
            else if (amountTotal < 18000) {
                livdomFee = 390
            }
            else if (amountTotal < 21000) {
                livdomFee = 190
            }
            else if (amountTotal >= 21000) {
                livdomFee = 0
            }
        }
    }

    const now = new Date()
    return {
        allowCreditPayment: amountTotal + livdomFee <= userCredit,
        livdomFee,
        blockInStorePayment:
            currentLocale.type === 2 ||
            currentLocale.partner ||
            currentLocale.private ||
            currentGlobalOrder.block_instore_payment
        ,
        blockOrderChanges: currentGlobalOrder.block_order_changes,
        globalOrderAlreadyClosed: {
            distributionDate: new Date(currentGlobalOrder.distribution_date),
            ordersClosingDate: new Date(convertToParseableRepresentation(currentGlobalOrder.order_end)),
            status: order && typeof order.globalOrderAlreadyClosed === 'boolean' ? order.globalOrderAlreadyClosed : new Date(convertToParseableRepresentation(currentGlobalOrder.order_end)) < now ? true : false,
        },
        isHomeDelivery: currentLocale.code === 'DOM',
        isOrderLocalePrivate: currentLocale && currentLocale.private,
        loadingPaymentTokens: currentUser.loading,
        orderreservation_id: order.id,
        paymentTokens: currentUser.payment_tokens || [],
        serverError: order && !!order.error,
    }
}
