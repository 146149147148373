import React, { Component } from 'react'
import Styles from './styles.module.css'
import KRGlue from '@lyracom/embedded-form-glue'
import { wrapClientApiCall } from '../../../../../../utils'
import { connect } from 'react-redux'
import axios from 'axios'
import { Dimmer, Loader } from 'semantic-ui-react'

class CheckoutPaymentCBIframe extends Component {
    constructor(props) {
        super(props)
        this.state = {
            message: '',
            payzenLoaded: false
        }
        this.paymentContainerRef = React.createRef()
    }

    async componentDidMount() {
        const endpoint = 'https://api.payzen.eu'
        const publicKey = '90087940:publickey_hO2B3l3W8pkF5wea7UJsY2WSNHpJKbbD8XGLldHmAaSFh'
        const formToken = this.props.formToken

        try {
            const { KR } = await KRGlue.loadLibrary(endpoint, publicKey)

            KR.removeForms()

            await KR.setFormConfig({
                formToken,
                'kr-language': 'fr-FR',
            })

            this.setState({
                payzenLoaded: true
            })
            await KR.renderElements('#myPaymentForm')

            KR.onSubmit(async paymentData => {
                try {
                    await wrapClientApiCall({
                        actionRoot: 'VERIFY_PAYMENT',
                        url: 'api/payment-verification',
                        method: 'POST',
                        body: {
                            clientAnswer: paymentData.clientAnswer,
                            hash: paymentData.hash,
                        },
                        hasJsonResponse: true,

                        successCallback: async ({ dispatch, data }) => {
                            this.setState({ message: '✅ Paiement validé avec succès !' })
                            window.location.href = `/checkout/commande-confirmee?order_id=${paymentData.clientAnswer.orderDetails.orderId}`
                        },
                        failureCallback: ({ dispatch, message }) => {
                            this.setState({
                                message: message || '❌ Paiement refusé ou invalide.',
                            })
                            window.location.href = '/checkout/commande-echouee'
                        },
                    })(this.props.dispatch, this.props.getState)
                } catch (err) {
                    console.error('Payment validation failed:', err)
                    this.setState({ message: 'Erreur réseau lors de la validation.' })
                }

                return false
            })
        } catch (error) {
            console.error('PayZen setup error:', error)
            this.setState({
                message: error.message || 'Erreur lors de l’affichage du paiement.',
            })
        }
    }

    render() {
        return (
            <div
                id="iframe_container"
                className={Styles.IframeContainer}
                data-testid="iframe-container"
            >
                {!this.state.payzenLoaded ?
                    <Dimmer active inverted>
                        <Loader active>Le formulaire de paiement est en cours de chargement...</Loader>
                    </Dimmer>
                    :
                    <div id="myPaymentForm" ref={this.paymentContainerRef}>
                        <div className="kr-smart-form" kr-card-form-expanded></div>
                    </div>
                }
                {this.state.message && (
                    <div data-test="payment-message">{this.state.message}</div>
                )}
            </div>
        )
    }
}

// ✅ Redux connect bindings outside the component
const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
    dispatch,
    getState: () => dispatch((_, getState) => getState()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentCBIframe)
