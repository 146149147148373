import { addImgPrefix } from '../../../../../utils'
import { Image, Icon } from 'semantic-ui-react'
import Styles from './styles.module.css'
import DateSlider from '../DateSlider'
import LocationPin from '../../../../../assets/location_pin.svg'
import googleMaps from '../../../../../assets/google-maps.webp'
import ChevronUp from '../../../../../assets/chevron-up.svg'
import ChevronDown from '../../../../../assets/chevron-down.svg'
import newLocale from '../../../../../assets/new-locale.png'
import newPrivateLocale from '../../../../../assets/new-private-locale.png'
import BackgroundImage from '../../../../ui/BackgroundImg'

import tomateRouge from '../../../../../assets/tomate-rouge.svg'
import tomateJaune from '../../../../../assets/tomate-jaune.svg'
import camion from '../../../../../assets/camion.svg'

const CardPartenaire = ({
    where,
    locale,
    handleMarkerAction,
    staticRef,
    currentUser,
    localeLength,
    openPanel,
    index,
    setOpenPanel,
    setMapCenter,
    mapCenter,
    setSelectedLocale,
    selectedLocale,
    handleNavigate,
}) => {
    const handleAddLocale = schedule => {
        handleNavigate(locale, schedule)
    }

    const zoomInLocaleStaticMap = locale => {
        if (window.innerWidth > 500 && locale) {
            const { lat, lng } = locale.geolocation
            if (staticRef && staticRef.current) {
                setMapCenter(locale.geolocation)
                staticRef.current.panTo({ lat, lng })
                staticRef.current.setZoom(16)
            }
        }
        else if (!locale) {
            setMapCenter(mapCenter)
            staticRef.current.setZoom(13)
        }
    }

    const localSchedule = locale.schedules.filter(function (val) {
        if (val === '' || val === undefined || val == null) {
            return false
        }
        return true
    })

    const distributions = locale.distributions
    const dayArray = ['L', 'M', 'M', 'J', 'V', 'S', 'D']

    const posMapping = { 0: 'Boutique', 1: 'Camion Kelbongoo', 2: 'Point Relais' }

    let cardContainer
    if (selectedLocale === locale) {
        cardContainer = Styles.cardContainerSelected;
    }
    else if (where === 'list') {
        if (locale.new && !locale.company_domain) {
            cardContainer = Styles.cardContainerNew;
        } else if (locale.company_domain) {
            cardContainer = Styles.cardContainerPrivateLocale;
        } else {
            cardContainer = Styles.cardContainer
        }
    }
    else {
        cardContainer = Styles.cardContainer
    }


    return (
        <div id={`key-${locale.code}`} className={cardContainer} style={where === 'sideMap' ? { backgroundColor: 'rgba(255, 255, 255, 0.88)', padding: '5px 10px' } : {}}>
            <div className={Styles.innerContainer}>
                {where === 'list' &&
                    <BackgroundImage
                        src={addImgPrefix(`${locale.code}.JPG`, 'boutique')} alt={`boutique ${locale.code}`}
                    />
                }

                <div className={Styles.infoContainer} style={where === 'sideMap' ? { minHeight: '120px' } : {}}>
                    <div className={Styles.titleContainer} style={where === 'sideMap' ? { padding: '5px 5px 0 5px', justifyContent: 'space-between' } : {}}>
                        <h2
                            className={Styles.title}
                            style={where === 'sideMap' ? { fontSize: '15px' } : {}}
                            onClick={() => {
                                if (where === 'list') {
                                    setSelectedLocale(locale)
                                    const { lat, lng } = locale.geolocation

                                    // Assuming you have a mapRef reference to your map component
                                    if (staticRef && staticRef.current) {
                                        setMapCenter(locale.geolocation)
                                        // Assuming your map component exposes a method to pan to the location
                                        staticRef.current.panTo({ lat, lng })
                                        staticRef.current.setZoom(15) // You can adjust the zoom level as needed
                                    }
                                }
                            }}
                        >
                            {locale.name}
                        </h2>
                        {(locale.new && !locale.company_domain && where === 'list') ? <Image className={Styles.sideImage} alt="nouveau" src={newLocale} /> : locale.company_domain && <Image className={Styles.sideImage} alt="nouveau" src={newPrivateLocale} />}
                        {where === 'sideMap' &&
                            <div
                                id="icon-button"
                                onClick={() => {
                                    handleMarkerAction(locale, false, index)
                                    document.getElementById("icon-button").blur()
                                }}
                                className={Styles.iconButton}
                                style={selectedLocale === locale ? { backgroundColor: '#63AE71' } : {}}
                            >
                                <Icon name='map' size='small' /><p>VOIR</p>
                            </div>}
                    </div>
                    <div className={Styles.flex}>
                        <Image className={Styles.cardIcons} src={LocationPin} alt="" />
                        <p className={Styles.address} style={where === 'sideMap' ? { fontSize: '12px' } : {}}>
                            {(locale.address.street && locale.address.street.split(',').length > 1 ? locale.address.street.split(',')[0] : locale.address.street) + ', ' + locale.address.zip + ' ' + locale.address.city}
                        </p>
                        {window.innerWidth < 500 && <a href={`https://maps.google.com/?ll=${locale.geolocation.lat},${locale.geolocation.lng}`} target="_blank"><Image style={{ width: '20px' }} src={googleMaps} alt="Google Maps" /></a>}
                    </div>

                    <div className={Styles.dayOpenedContainer}>
                        <>
                            {locale.type === 0 ?
                                <Image className={Styles.cardIcons} src={tomateRouge} alt="" />
                                :
                                locale.type === 1 ?
                                    <Image className={Styles.cardIconsLarger} src={camion} alt="" />
                                    :
                                    locale.type === 2 &&
                                    <Image className={Styles.cardIcons} src={tomateJaune} alt="" />
                            }
                            <p className={Styles.undertitle} style={where === 'map' ? { fontSize: '12px' } : {}}>
                                {posMapping[locale.type]}
                            </p>
                            {dayArray.map((day, index) => {
                                return (
                                    <div
                                        className={
                                            distributions.find(d => d.day === index + 1)
                                                ? Styles.dayContainerGreen
                                                : Styles.dayContainerGrey
                                        }
                                    >
                                        {day}
                                    </div>
                                )
                            })}
                        </>
                    </div>
                    {where === 'list' &&
                        <div
                            className={Styles.expandContainer}
                            onClick={() => {
                                const newArr = new Array(localeLength).fill(false)

                                if (openPanel[index] === true) {
                                    newArr[index] = false
                                    setOpenPanel(newArr)
                                } else {
                                    newArr[index] = true
                                    setOpenPanel(newArr)
                                }
                            }}
                        >
                            <p className={Styles.pointRetrait}>
                                {openPanel[index]
                                    ? 'Réduire'
                                    : 'Choisir un jour de retrait'}
                            </p>
                            {openPanel[index] ? (
                                <Image style={{ width: '20px' }} src={ChevronUp} alt="cacher ventes" />
                            ) : (
                                <Image style={{ width: '20px' }} src={ChevronDown} alt="montrer ventes" />
                            )}
                        </div>
                    }
                </div>
            </div>

            {where === 'list' && openPanel[index] && (
                <DateSlider
                    setSelectedLocale={setSelectedLocale}
                    currentUser={currentUser}
                    selectedLocale={selectedLocale}
                    locale={locale}
                    zoomInLocaleStaticMap={zoomInLocaleStaticMap}
                    localeSchedule={localSchedule}
                    handleNavigate={handleAddLocale}
                />
            )}
        </div>
    )
}

export default CardPartenaire
