import { Cart, GlobalOrder, Consumer } from '../../../../../models'

export const mapStateToProps = ({
    cart: { list, items: quantities, globalorder_id: cart_globalorder_id },
    order: currentOrder,
    products,
    available: { products: available },
    currentUser,
    currentGlobalOrder,
    currentLocale,
    livdomZipcodes,
    initRequest,
}) => {
    const isLocaleHomeDelivery = currentLocale && currentLocale.code === 'DOM'

    const cart = new Cart({
        list,
        quantities,
        products,
        available,
        isLocalePrivate: currentLocale && !!currentLocale.private,
        isLocaleHomeDelivery,
        currentLocale,
    })

    const consumer = new Consumer(currentUser)
    const welcomeCredit = currentUser.welcome_credit || 0;
    const ready = !!initRequest.ready
    const loading = !!currentUser.loading
    const orderreservation_id = currentOrder && currentOrder.id
    const amountTotal = cart.getTotalTTC()

    // Assessing userZone details if livdom
    let livdomFee = 0
    if (currentLocale && currentLocale.code === 'DOM') {
        let livdomZip = currentUser?.address_zip || null
        let userZone = (livdomZipcodes &&
            (livdomZipcodes.content[0].zone1.includes(livdomZip) ?
                1
                :
                livdomZipcodes.content[0].zone2.includes(livdomZip) ?
                    2
                    :
                    livdomZipcodes.content[0].zone3.includes(livdomZip) && 3)) || null
        if (userZone === 1) {
            if (amountTotal < 6000) {
                livdomFee = 890
            }
            else if (amountTotal < 9000) {
                livdomFee = 690
            }
            else if (amountTotal < 12000) {
                livdomFee = 390
            }
            else if (amountTotal < 15000) {
                livdomFee = 190
            }
            else if (amountTotal >= 15000) {
                livdomFee = 0
            }
        }
        else if (userZone === 2) {
            if (amountTotal < 9000) {
                livdomFee = 890
            }
            else if (amountTotal < 12000) {
                livdomFee = 690
            }
            else if (amountTotal < 15000) {
                livdomFee = 390
            }
            else if (amountTotal < 18000) {
                livdomFee = 190
            }
            else if (amountTotal >= 18000) {
                livdomFee = 0
            }
        }
        else if (userZone === 3) {
            if (amountTotal < 12000) {
                livdomFee = 890
            }
            else if (amountTotal < 15000) {
                livdomFee = 690
            }
            else if (amountTotal < 18000) {
                livdomFee = 390
            }
            else if (amountTotal < 21000) {
                livdomFee = 190
            }
            else if (amountTotal >= 21000) {
                livdomFee = 0
            }
        }
    }

    const creditUsed = cart.getCreditUsed(currentUser.current_credit, livdomFee)

    const redirectToAccount =
        !!ready &&
        !loading &&
        isLocaleHomeDelivery &&
        !consumer.hasValidDeliveryAddress()

    return {
        cart_globalorder_id,
        orderreservation_id,
        cartItems: cart.items,
        amountTotal,
        addedCharges: cart.added_charges,
        amountToPay: amountTotal - creditUsed - welcomeCredit + livdomFee,
        currentGlobalOrder: new GlobalOrder(currentGlobalOrder),
        currentUser,
        loading,
        redirectToAccount,
        creditUsed,
        livdomFee,
        welcomeCredit,
        currentLocale,
        ready,
    }
}
